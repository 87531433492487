import { adoptionDataFormatter, adoptionOverallFormatter, offeringFormatter, stakingOverallFormatter } from 'utils/bc-formatter';
import BlockChain from 'web3/Blockchain';
import { DASHBOARD_ERROR, SET_DASHBOARD } from '../reducers/dashboard';
import axios from 'axios';
import gateway from 'integrations/gateway';

export const get = () => async (dispatch) => {
    const { contracts } = BlockChain.getInfo();
    try {
        const [amplifierInfo, offerings, overalls] = await Promise.all([
            contracts.adoptionAmplifier?.getData?.() ?? {},
            contracts.adoptionAmplifier?.getOfferings?.() ?? [],
            axios.get(`${gateway.endpoint}/v1/stats`),
        ]);

        return dispatch({
            payload: {
                amplifier: {
                    data: adoptionDataFormatter(amplifierInfo),
                    offerings: offerings.map((offering) => offeringFormatter(offering)),
                },
                overalls: {
                    adoption: adoptionOverallFormatter(overalls.data.adoption),
                    staking: overalls.data.staking && stakingOverallFormatter(overalls.data.staking),
                },
            },
            type: SET_DASHBOARD,
        });
    } catch (error) {
        console.log(error);
        dispatch({
            type: DASHBOARD_ERROR,
        });
    }
};

export const set = (payload) => (dispatch, getState) => {
    return dispatch({
        payload,
        type: SET_DASHBOARD,
    });
};
